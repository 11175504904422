import React, { Fragment, useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import { CompanyContext } from "../../context/CompanyContext";
import getImage from "../../components/GetImage/GetImage";
import getFile from "../../components/GetFile/GetFile";
import VesselCrewTable from "./VesselCrewTable";
import VesselGallery from "./VesselGallery";
import Ladder from "../Ladder/Ladder";
import Ancillary from "../Ancillary/Ancillary";
import AddLadderModal from "../Ladder/AddLadderModal";
import RemoveLadderModal from "../Ladder/RemoveLadderModal";
import UseLadderModal from "../Ladder/UseLadderModal";
import InspectLadderModal from "../Ladder/InspectLadderModal";
import InstallSpareLadderModal from "../Ladder/InstallSpareLadderModal";
import ViewAncillaryModal from "../Ancillary/ViewAncillaryModal";
import EmailCertificatesModal from "./EmailCertificatesModal";
import EnvelopeIcon from "@heroicons/react/24/outline/EnvelopeIcon";
import PhoneIcon from "@heroicons/react/24/outline/PhoneIcon";
import PhotographIcon from "@heroicons/react/24/outline/PhotoIcon";
import XIcon from "@heroicons/react/24/outline/XMarkIcon";
import UserIcon from "@heroicons/react/24/outline/UserIcon";
import { getVesselUsers, getVessel } from "../../services/strapiService";

export default function Vessel() {
  const company = useContext(CompanyContext);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { id } = useParams();
  const [data, setData] = useState(null);
  const [ladderPos, setLadderPos] = useState(null);
  const [ladderId, setLadderId] = useState(null);
  const [ancillaryType, setAncillaryType] = useState(null);
  const [ancillaryItemIndex, setAncillaryItemIndex] = useState(null);
  const [ancillaryItemEdit, setAncillaryItemEdit] = useState(null);

  const vesselCrew = useQuery(["crew", id], () => getVesselUsers(id));
  const vesselInfo = useQuery(["vessel", id], () => getVessel(id));

  useEffect(() => {
    // Filter the vessel array based on the ID from the URL
    const filteredVessel = company?.vessels?.find((s) => s.id === parseInt(id));

    // Update state with the filtered data
    setData(filteredVessel);
  }, [id, company?.vessels]);

  const getLadderDocument = async (certificateFile) => {
    //const certificateFile = data[`ladder${id}`].certificateFile;
    const fileUrl = getFile({ file: certificateFile });
    window.open(fileUrl, "_blank");
  };

  const handleEmailClick = (email) => {
    window.location.href = `mailto:${email}`;
  };
  const handleTelClick = () => {
    window.location.href = `tel:${data.telephone}`;
  };

  const [isGalleryOpen, setGalleryOpen] = useState(false);

  const openGallery = () => {
    setGalleryOpen(true);
  };

  const closeGallery = () => {
    setGalleryOpen(false);
  };

  const showLadderHistory = () => {
    navigate(`/vessel/${id}/ladder-history`);
  };

  const [showAddLadderModal, setShowAddLadderModal] = useState(false);
  const showAddLadder = (pos) => {
    setLadderPos(pos);
    setShowAddLadderModal(true);
  };
  const [showRemoveLadderModal, setShowRemoveLadderModal] = useState(false);
  const showRemoveLadder = (pos, ladderId) => {
    setLadderPos(pos);
    setLadderId(ladderId);
    setShowRemoveLadderModal(true);
  };
  const [showUseLadderModal, setShowUseLadderModal] = useState(false);
  const showUseLadder = (pos) => {
    setLadderPos(pos);
    setShowUseLadderModal(true);
  };
  const [showInspectLadderModal, setShowInspectLadderModal] = useState(false);
  const showInspectLadder = (pos, ladderId) => {
    setLadderId(ladderId);
    setLadderPos(pos);
    setShowInspectLadderModal(true);
  };
  const [showInstallSpareLadderModal, setShowInstallSpareLadderModal] =
    useState(false);
  const showInstallSpareLadder = (pos, ladderId) => {
    setLadderPos(pos);
    setLadderId(ladderId);
    setShowInstallSpareLadderModal(true);
  };
  const [showViewAncillaryModal, setShowViewAncillaryModal] = useState(false);
  const showViewAncillary = (type, itemIndex, item) => {
    setAncillaryType(type);
    setAncillaryItemIndex(itemIndex);
    setAncillaryItemEdit(item);
    setShowViewAncillaryModal(true);
  };
  const [showEmailCertificatesModal, setShowEmailCertificatesModal] =
    useState(false);
  const showEmailCertificates = () => {
    setShowEmailCertificatesModal(true);
  };

  const removeLadderMutation = useMutation(
    (vesselId, ladderPosR) => {
      let token =
        localStorage.getItem("user") &&
        JSON.parse(localStorage.getItem("user")).jwt;
      return axios.post(
        `${process.env.REACT_APP_API_ROOT}/ladders/remove`,
        {
          vesselId: vesselId,
          ladderPos: ladderPos,
          ladderId:ladderId
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["user"]);
        queryClient.invalidateQueries(["company"]);
        setShowRemoveLadderModal(false);
      },
    }
  );

  const removeLadder = (vesselId, ladderPosR) => {
    removeLadderMutation.mutate(vesselId, ladderPosR);
  };

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="relative pb-5 border-b border-gray-200 sm:pb-0">
        <div className="mt-4 my-5">
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12 sm:col-span-4">
              <div className="mx-auto overflow-hidden bg-white rounded-lg shadow divide-y divide-gray-200">
                <div className="relative">
                  <img
                    className="w-full h-48 object-cover"
                    src={getImage({
                      image: data.photo,
                      desiredFormat: "small",
                    })}
                    alt={data.name}
                  />
                  {company && company.logo && (
                    <img
                      className="absolute top-2 left-2 h-12 rounded-lg bg-white p-2"
                      src={getImage({
                        image: company.logo,
                      })}
                      alt={company.name}
                    />
                  )}

                  <div className="absolute bottom-2 right-2 rounded-lg bg-white p-2 flex items-center justify-center">
                    <PhotographIcon className="w-5 h-5 mr-2 text-fathom-blue" />
                    <p onClick={openGallery} className="hover:cursor-pointer">
                      Photos
                    </p>
                  </div>

                  {/* Render VesselGallery as a dialog */}
                  <Transition appear show={isGalleryOpen} as={Fragment}>
                    <Dialog
                      as="div"
                      className="fixed inset-0 z-50 overflow-y-auto"
                      onClose={closeGallery}
                    >
                      <div className="min-h-screen flex items-center justify-center">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="inline-block align-middle">
                          &#8203;
                        </span>

                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                        >
                          <div className="inline-block w-full max-w-screen-xl p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
                            <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                              <button
                                type="button"
                                className="bg-white rounded-md text-gray-400 hover:text-gray-500"
                                onClick={() => setGalleryOpen(false)}
                              >
                                <span className="sr-only">Close</span>
                                <XIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                            <VesselGallery onClose={closeGallery} />
                          </div>
                        </Transition.Child>
                      </div>
                    </Dialog>
                  </Transition>
                </div>
                <div className="p-4">
                  <div className="mb-4">
                    <div>
                      <h2 className="text-xl font-semibold">{data.name}</h2>
                      <p className="text-gray-600 text-sm">
                        IMO:{" "}
                        <a
                          href={`https://www.marinetraffic.com/en/ais/home/imo:${data.IMO}/zoom:13`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {data.IMO}
                        </a>
                      </p>
                    </div>
                  </div>

                  <div className="flex items-center mb-2">
                    <EnvelopeIcon className="h-5 w-5 mr-2 text-gray-500" />
                    <p
                      className="text-gray-700 hover:underline hover:cursor-pointer text-sm"
                      onClick={() => handleEmailClick(data.email)}
                    >
                      {data.email}
                    </p>
                  </div>

                  <div className="flex items-center mb-2">
                    <PhoneIcon className="h-5 w-5 mr-2 text-gray-500" />
                    <p
                      className="text-gray-700 hover:underline hover:cursor-pointer text-sm"
                      onClick={handleTelClick}
                    >
                      {data.telephone}
                    </p>
                  </div>
                  {data.marineSuperintendent && (
                    <div className="flex items-center mb-2">
                      <UserIcon className="h-5 w-5 mr-2 text-gray-500" />
                      <p
                        className="text-gray-700 hover:underline hover:cursor-pointer text-sm"
                        onClick={() =>
                          handleEmailClick(data.marineSuperintendentEmail)
                        }
                      >
                        Marine Superintendent: {data.marineSuperintendent}
                      </p>
                    </div>
                  )}
                  {data.technicalSuperintendent && (
                    <div className="flex items-center mb-2">
                      <UserIcon className="h-5 w-5 mr-2 text-gray-500" />
                      <p
                        className="text-gray-700 hover:underline hover:cursor-pointer text-sm"
                        onClick={() =>
                          handleEmailClick(data.technicalSuperintendentEmail)
                        }
                      >
                        Technical Superintendent: {data.technicalSuperintendent}
                      </p>
                    </div>
                  )}
                  {data.technicalOfficer && (
                    <div className="flex items-center mb-2">
                      <UserIcon className="h-5 w-5 mr-2 text-gray-500" />
                      <p
                        className="text-gray-700 hover:underline hover:cursor-pointer text-sm"
                        onClick={() =>
                          handleEmailClick(data.technicalOfficerEmail)
                        }
                      >
                        ⁠Technical Officer: {data.technicalOfficer}
                      </p>
                    </div>
                  )}
                  <div className="flex">
                    <button
                      className="m-1 flex-1 w-full items-center px-3 py-2 border border-transparent text-xs sm:text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-fathom-blue hover:bg-fathom-dark-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"
                      onClick={showLadderHistory}
                    >
                      View Ladder History
                    </button>

                    <button
                      className="m-1 flex-1 w-full items-center px-3 py-2 border border-transparent text-xs sm:text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-fathom-blue hover:bg-fathom-dark-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"
                      onClick={showEmailCertificates}
                    >
                      Email Certificates
                    </button>
                  </div>
                </div>
              </div>
              <div className="mx-auto mt-6 overflow-hidden bg-white rounded-lg shadow divide-y divide-gray-200">
                <div className="p-4">
                  <h2 className="text-xl font-semibold">
                    Ladder Specifications
                  </h2>
                </div>
                <div className="p-4">
                  <dl className="grid grid-cols-2 gap-x-3 gap-y-6">
                    <div>
                      <dt className="font-medium text-gray-900">Type</dt>
                      <dd className="mt-2 text-sm text-gray-500">
                        {data.type}
                      </dd>
                    </div>
                    <div>
                      <dt className="font-medium text-gray-900">Length</dt>
                      <dd className="mt-2 text-sm text-gray-500">
                        {data.length}
                      </dd>
                    </div>
                    <div>
                      <dt className="font-medium text-gray-900">
                        Securing Tails
                      </dt>
                      <dd className="mt-2 text-sm text-gray-500">
                        {data.securing}
                      </dd>
                    </div>
                    <div>
                      <dt className="font-medium text-gray-900">Steps</dt>
                      <dd className="mt-2 text-sm text-gray-500">
                        {data.steps}
                      </dd>
                    </div>
                    <div>
                      <dt className="font-medium text-gray-900">Spreaders</dt>
                      <dd className="mt-2 text-sm text-gray-500">
                        {data.spreaders}
                      </dd>
                    </div>
                    <div>
                      <dt className="font-medium text-gray-900">
                        Rubber Steps
                      </dt>
                      <dd className="mt-2 text-sm text-gray-500">
                        {data.rubberSteps}
                      </dd>
                    </div>
                    <div>
                      <dt className="font-medium text-gray-900">Securing</dt>
                      <dd className="mt-2 text-sm text-gray-500">
                        {data.securing}
                      </dd>
                    </div>
                    <div>
                      <dt className="font-medium text-gray-900">
                        Max Ladder Life
                      </dt>
                      <dd className="mt-2 text-sm text-gray-500">
                        {company.maxLadderLife} Months
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
            <div className="col-span-12 sm:col-span-8">
              <div className="mx-auto overflow-hidden bg-white rounded-lg shadow divide-y divide-gray-200">
                <div className="p-4">
                  <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                    <Ladder
                      ladderData={data.ladder1}
                      ladderPos={1}
                      showAddLadder={showAddLadder}
                      showRemoveLadder={showRemoveLadder}
                      showUseLadder={showUseLadder}
                      getLadderDocument={getLadderDocument}
                      showInspectLadder={showInspectLadder}
                      maxLadderLife={company.maxLadderLife}
                    />
                    <Ladder
                      ladderData={data.ladder2}
                      ladderPos={2}
                      showAddLadder={showAddLadder}
                      showRemoveLadder={showRemoveLadder}
                      showUseLadder={showUseLadder}
                      getLadderDocument={getLadderDocument}
                      showInspectLadder={showInspectLadder}
                      showInstallSpareLadder={showInstallSpareLadder}
                      maxLadderLife={company.maxLadderLife}
                    />
                    <Ladder
                      ladderData={data.ladder3}
                      ladderPos={3}
                      showAddLadder={showAddLadder}
                      showRemoveLadder={showRemoveLadder}
                      showUseLadder={showUseLadder}
                      getLadderDocument={getLadderDocument}
                      showInspectLadder={showInspectLadder}
                      maxLadderLife={company.maxLadderLife}
                    />
                  </div>
                  <div>
                    <h2 className="text-xl font-semibold text-center mt-8 mb-1">Additional Spare ladders</h2>
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                      {data.additional_ladders && data.additional_ladders.map((item, index) => (
                         <Ladder
                         key={"additional_ladder"+index}
                         ladderData={item}
                         ladderPos={"additional_spare"}
                         showAddLadder={showAddLadder}
                         showRemoveLadder={showRemoveLadder}
                         showUseLadder={showUseLadder}
                         getLadderDocument={getLadderDocument}
                         showInspectLadder={showInspectLadder}
                         showInstallSpareLadder={showInstallSpareLadder}
                         maxLadderLife={company.maxLadderLife}
                       />
                      ))}
                      <Ladder showAddLadder={showAddLadder}
                    />
                  </div>
                </div>
              </div>
              <div className="mx-auto mt-6 overflow-hidden bg-white rounded-lg shadow divide-y divide-gray-200">
                <div className="p-4">
                  <h2 className="text-xl font-semibold text-center">
                    Ancillary Equipment
                  </h2>
                  <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mt-4">
                    <div className="text-center">
                      <h3 className="text-lg">Securing Ropes</h3>
                      <div className="flex justify-center mt-4 mb-4">
                        {vesselInfo &&
                          vesselInfo.data &&
                          vesselInfo.isSuccess && (
                            <Ancillary
                              type="securingRopes"
                              maxLife={company.maxSecuringRopesLife}
                              showViewAncillary={(type, itemIndex, item) =>
                                showViewAncillary(type, itemIndex, item)
                              }
                              ancillaryData={vesselInfo?.data}
                            />
                          )}
                      </div>
                    </div>
                    <div className="text-center">
                      <h3 className="text-lg">Manropes</h3>
                      <div className="flex justify-center mt-4 mb-4">
                        <Ancillary
                          type="manropes"
                          maxLife={company.maxManropesLife}
                          showViewAncillary={(type, itemIndex, item) =>
                            showViewAncillary(type, itemIndex, item)
                          }
                          ancillaryData={vesselInfo?.data}
                        />
                      </div>
                    </div>
                    <div className="text-center">
                      <h3 className="text-lg">Accom Ladder Falls</h3>
                      <div className="flex justify-center mt-4 mb-4">
                        <Ancillary
                          type="accommodationLadderFalls"
                          maxLife={company.maxAccomLdrFallsLife}
                          showViewAncillary={(type, itemIndex, item) =>
                            showViewAncillary(type, itemIndex, item)
                          }
                          ancillaryData={vesselInfo?.data}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mx-auto mt-6 overflow-hidden bg-white rounded-lg shadow divide-y divide-gray-200">
                <div className="p-4">
                  <h2 className="text-xl font-semibold text-center">
                    Embarkation Ladders
                  </h2>
                  <div className="grid grid-cols-6 gap-4 mt-4">
                    <Ancillary
                      type="embarkationLadders"
                      maxLife={company.maxEmbarkationLife}
                      showViewAncillary={(type, itemIndex, item) =>
                        showViewAncillary(type, itemIndex, item)
                      }
                      ancillaryData={vesselInfo?.data}
                    />
                  </div>
                </div>
              </div>

              <div className="mx-auto mt-6 overflow-hidden bg-white rounded-lg shadow divide-gray-200">
                <div className="p-4">
                  <h2 className="text-xl font-semibold">Crew List</h2>
                </div>
                {vesselCrew.isSuccess && vesselCrew.data && (
                  <VesselCrewTable data={vesselCrew.data?.data} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddLadderModal
        showModal={showAddLadderModal}
        setShowModal={setShowAddLadderModal}
        vesselId={id}
        ladderPos={ladderPos}
      />
      <RemoveLadderModal
        showModal={showRemoveLadderModal}
        setShowModal={setShowRemoveLadderModal}
        vesselId={id}
        ladderPos={ladderPos}
        removeLadder={removeLadder}
      />
      <UseLadderModal
        showModal={showUseLadderModal}
        setShowModal={setShowUseLadderModal}
        vesselId={id}
        ladderPos={ladderPos}
        ladderId={data[`ladder${ladderPos}`]?.id}
      />
      <InspectLadderModal
        showModal={showInspectLadderModal}
        setShowModal={setShowInspectLadderModal}
        vesselId={id}
        ladderPos={ladderPos}
        showInspectLadder={showInspectLadder}
        ladderId={ladderId}
      />
      <InstallSpareLadderModal
        showModal={showInstallSpareLadderModal}
        setShowModal={setShowInstallSpareLadderModal}
        vesselId={id}
        showInstallSpareLadder={showInstallSpareLadder}
        vessel={data}
        ladderId={ladderId}
        ladderPos={ladderPos}
      />
      <ViewAncillaryModal
        showModal={showViewAncillaryModal}
        setShowModal={setShowViewAncillaryModal}
        vesselId={id}
        ancillaryType={ancillaryType}
        ancillaryItemIndex={ancillaryItemIndex}
        ancillaryItemEdit={ancillaryItemEdit}
      />
      <EmailCertificatesModal
        showModal={showEmailCertificatesModal}
        setShowModal={setShowEmailCertificatesModal}
        vesselId={id}
        vessel={data}
      />
    </>
  );
}
